/*@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@1,100..900&display=swap');*/

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

    @font-face {
        font-family: Inter Tight;
        src: url("assets/fonts/InterTight-VariableFont_wght.ttf");
    }

    @font-face {
        font-family: "Helvetica Neue Bold";
        font-weight: 700;
        src: url("assets/fonts/HelveticaNeueBold.ttf");
    }

    html {
        font-family: "Inter Tight", sans-serif;
        font-optical-sizing: auto;
    }

    :root {
        --background: 221.2 0% 100%;
        --foreground: 221.2 0% 10%;
        --card: 221.2 0% 100%;
        --card-foreground: 221.2 0% 15%;
        --popover: 221.2 0% 100%;
        --popover-foreground: 221.2 95% 10%;
        --primary: 221.2 83.2% 53.3%;
        --primary-foreground: 0 0% 100%;
        --secondary: 221.2 10% 90%;
        --secondary-foreground: 0 0% 0%;
        --muted: 183.2 10% 95%;
        --muted-foreground: 221.2 0% 40%;
        --accent: 183.2 10% 90%;
        --accent-foreground: 221.2 0% 15%;
        --destructive: 0 50% 50%;
        --destructive-foreground: 221.2 0% 100%;
        --border: 221.2 20% 82%;
        --input: 221.2 20% 50%;
        --ring: 221.2 83.2% 53.3%;
        --radius: 1rem;
    }

    .dark {
        --background: 221.2 32.89% 7.93%;
        --foreground: 221.2 0% 100%;
        --card: 221.2 20% 7.93%;
        --card-foreground: 221.2 0% 100%;
        --popover: 221.2 10% 5%;
        --popover-foreground: 221.2 0% 100%;
        --primary: 221.2 83.2% 53.3%;
        --primary-foreground: 0 0% 100%;
        --secondary: 221.2 10% 20%;
        --secondary-foreground: 0 0% 100%;
        --muted: 221 77.67% 6.29%;
        --muted-foreground: 221.2 0% 65%;
        --accent: 183.2 10% 25%;
        --accent-foreground: 221.2 0% 95%;
        --destructive: 0 50% 50%;
        --destructive-foreground: 221.2 0% 100%;
        --border: 221.7 22.8% 19.8%;
        --input: 222.4 20% 33.3%;
        --ring: 221.2 83.2% 53.3%;
        --radius: 1rem;
    }
}


@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

/* For logo */
/*html.dark {*/
/*    .aicaller-sidebar-logo {*/
/*        */
/*    }*/
/*}*/

/*html.light {*/
/*    .aicaller-sidebar-logo {*/

/*    }*/
/*}*/

.font-helvetica-neue-bold {
    font-family: "Helvetica Neue Bold", serif !important;
}
